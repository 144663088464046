import { identity, isEmpty, isNil } from "ramda";
import { youtubeLinkRegex } from "./regex";

/**
 * Check if two arrays have the same values, but maybe in different order.
 *
 * @NOTE This is not a deep check
 */
export const equalValues = <T>(xs: T[], ys: T[]): boolean => {
  return xs.every((x) => ys.includes(x)) && ys.every((y) => xs.includes(y));
};

/**
 * A type-safer `Object.entries`.
 * @NOTE TS adopts the extra-safe approach, as Object.keys can technically return extra
 * properties added by mutation or from enumerable properties from prototype
 */
export const objectEntries = <
  T extends Record<string, unknown>,
  K extends keyof T
>(
  object: T
): Array<[K, T[K]]> => Object.entries(object) as Array<[K, T[K]]>;

/**
 * A type-safer `Object.keys`.
 * @NOTE TS adopts the extra-safe approach, as Object.keys can technically return extra
 * properties added by mutation or from enumerable properties from prototype
 */
export const objectKeys = <
  T extends Record<string, unknown>,
  K extends keyof T
>(
  object: T
): K[] => Object.keys(object) as K[];

export const objectMap = <T>(
  obj: { [k in PropertyKey]: T },
  fn: (value: T, key: PropertyKey, index?: number) => T
): { [k in PropertyKey]: T } =>
  Object.fromEntries(
    Object.entries(obj).map(
      ([key, value], index) => [key, fn(value, key, index)] as const
    )
  );

// used as array.filter(isDefined), where:
// input array is (T | undefined)[]
// output array is T[]
export const isDefined = <T>(t: T | undefined | null): t is T => !isNil(t);

export const isTruthy = <T>(t: T | undefined | null): t is T => !!t;

/**
 * Remove properties whose values are `undefined`.
 */
export const shallowCleanObject = <T extends object>(
  object: Partial<T>
): ToOptional<T> => {
  const entries = Object.entries(object).filter(([_, v]) => v !== undefined);
  return Object.fromEntries(entries) as ToOptional<T>;
};

export const isOnlyValuesOf = (
  obj: Record<string, unknown>,
  valueToCheck: unknown
): boolean => {
  return Object.values(obj).every((value) =>
    isObject(value) ? isOnlyEmptyValues(value) : value === valueToCheck
  );
};

/**
 * Deeply remove properties whose values are `undefined`.
 */
export const deepCleanObject = <T extends object>(
  object: DeepPartialExcludeFabric<T>
): DeepPartialExcludeFabric<T> => {
  return Object.fromEntries(
    Object.entries(object)
      .map(([key, value]) => {
        const cleanedValue = isObject(value)
          ? isOnlyValuesOf(value, undefined)
            ? undefined
            : deepCleanObject(value as DeepPartialExcludeFabric<T>)
          : isObject(value) && isEmpty(value)
          ? undefined
          : value;

        return [key, cleanedValue];
      })
      .filter(([, value]) => value !== undefined)
  ) as T;
};

export const clamp = (v: number, min: number, max: number): number =>
  max > min ? Math.max(Math.min(v, max), min) : Math.max(Math.min(v, min), max);

export const clampToSafeInteger = (v: number): number =>
  clamp(v, Number.MIN_SAFE_INTEGER, Number.MAX_SAFE_INTEGER);

/**
 * Helper to ensure state is not mutated.
 */
export const deepFreeze = <T extends Record<string, unknown>>(object: T): T => {
  const propNames = Object.getOwnPropertyNames(object);

  for (const name of propNames) {
    const value = object[name];

    if (isObject(value)) {
      deepFreeze(value); // eslint-disable-line @typescript-eslint/no-unused-vars
    }
  }

  return Object.freeze(object);
};

const indexToSize = (
  index: number,
  {
    min,
    max,
    length,
  }: {
    min: number;
    max: number;
    length: number;
  }
) => {
  const idx = clamp(index, 1, length);
  const step = (Math.log(max) - Math.log(min)) / (length - 1);
  const size = Math.round(Math.exp(Math.log(min) + idx * step));
  return clamp(size, min, max);
};

/**
 * Random number between [min, max] (included)
 */
export const randomInRange = (min: number, max: number): number => {
  return Math.floor(Math.random() * (max - min + 1)) + min;
};

export const range = (
  min: number,
  max: number,
  length: number
): Record<number, string> => {
  const threshold = max / 10;

  return Array.from({ length }, (v, k) => k + min).reduce<
    Record<number, string>
  >((pv, v) => {
    const key =
      v < threshold
        ? v
        : indexToSize(v - threshold, {
            min: threshold,
            max,
            length: length - threshold,
          });
    pv[key] = "";
    return pv;
  }, {});
};

/**
 * Implementation taken from https://github.com/euank/node-parse-numeric-range/blob/master/index.js
 */
export const rangeParser = (expression: string): number[] => {
  const res: number[] = [];
  let m;

  for (const str of expression.split(",").map((str) => str.trim())) {
    // just a number
    if (/^-?\d+$/.test(str)) {
      res.push(parseInt(str, 10));
    } else if (
      (m = str.match(/^(-?\d+)(-|\.\.\.?|\u2025|\u2026|\u22EF)(-?\d+)$/))
    ) {
      // 1-5 or 1..5 (equivalent) or 1...5 (doesn't include 5)
      const [, lhs_string, sep, rhs_string] = m;

      if (lhs_string && rhs_string) {
        const lhs = parseInt(lhs_string);
        let rhs = parseInt(rhs_string);
        const incr = lhs < rhs ? 1 : -1;

        // Make it inclusive by moving the right 'stop-point' away by one.
        if (sep === "-" || sep === ".." || sep === "\u2025") {
          rhs += incr;
        }

        for (let i = lhs; i !== rhs; i += incr) {
          res.push(i);
        }
      }
    }
  }

  return res;
};

/**
 * @TODO #6553 - Migrate to LineAnchorId and remove all uses of this for Line
 */
export const getKeyByValue = <Key extends string, Value>(
  object: Record<Key, Value>,
  value: Value
): Key | undefined =>
  (Object.keys(object) as Key[]).find((key: Key) => object[key] === value);

export const noop = (): void => void {};

export const unload = (fn: () => void): void => {
  window.addEventListener("beforeunload", fn);

  // For iframes
  window.addEventListener("unload", fn);
};

const isCloneableValue = (value: unknown): boolean => {
  return typeof value !== "function" && !(value instanceof window.Element);
};

// eslint-disable-next-line @typescript-eslint/ban-types
export const isFunction = (obj: unknown): obj is Function =>
  typeof obj === "function";

export const isObject = (value: unknown): value is Record<string, unknown> => {
  return typeof value === "object" && !Array.isArray(value) && value !== null;
};

export const isNumber = (value: unknown): value is number => {
  return Number.isFinite(value);
};

export const isPromise = <T>(value: unknown): value is Promise<T> =>
  isObject(value) && isFunction(value.then);

/** Transform the keys of an object to start with lowercase */
export const toLowerCaseObj = <T extends Record<string, unknown>>(
  obj: T
): T => {
  return Object.fromEntries(
    Object.entries(obj).map(([key, value]) => [
      key.charAt(0).toLowerCase() + key.slice(1),
      value,
    ])
  ) as T;
};

/**
 * Remove non serializable properties from the object
 */
export const normalizeToCloneable = <T>(object: T): Cloneable<T> => {
  if (typeof object !== "object" || object === null) {
    return object as unknown as Cloneable<T>;
  }

  const target: any = Array.isArray(object) ? [] : {}; // eslint-disable-line @typescript-eslint/no-explicit-any
  for (const key in object) {
    const value = object[key];
    if (isCloneableValue(value)) {
      target[key] = normalizeToCloneable(value);
    }
  }

  /**
   * Ensure non-enumerable properties are cloned as well. This is a special
   * handling for objects like Error, where properties 'message' and 'stack' are
   * not enumerable and would result in `{}` being returned
   */
  Object.getOwnPropertyNames(object).forEach((key) => {
    const value = object[key as Extract<keyof T, string>];
    if (!target[key] && isCloneableValue(value)) {
      target[key] = normalizeToCloneable(value);
    }
  });

  return target;
};

/** Get the difference (xor) of two arrays. Values are checked by reference by default */
export const differenceBy = <T>(
  xs: T[],
  ys: T[],
  keyFn: (value: T) => unknown = identity
) => {
  return xs.filter((x) => !ys.find((y) => keyFn(x) === keyFn(y)));
};

/**
 * @NOTE The array must not be empty. If you want a safer alternative that can return undefined, use
 * ramda's `last`
 */
export const last = <T>(xs: T[]): T => xs[xs.length - 1];

type SplitBy = {
  <T, U extends T>(
    values: T[],
    predicate: (value: T, index: number) => value is U
  ): [U[], Exclude<T, U>[]];
  <T>(values: T[], predicate: (value: T, index: number) => boolean): [T[], T[]];
};

/**
 * Split a group into two subgroups according to a predicate
 *
 * @example
 *
 * ```ts
 * const [yes, no] = splitBy([1, 2, 3], x => x < 2)
 * ```
 */
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const splitBy: SplitBy = (values: any[], predicate: any) => {
  return values.reduce(
    (result, value, index) => {
      const [yes, no] = result;

      predicate(value, index) ? yes.push(value) : no.push(value);

      return result;
    },
    [[], []]
  );
};

/**
 * Split an array into adjacent pairs
 *
 * @example
 *
 * ```ts
 * splitIntoAdjacentPairs([1, 2, 3]) // [[1, 2], [2, 3]]
 * ```
 */
export const splitIntoAdjacentPairs = <T>(xs: T[]): Array<[T, T]> => {
  return xs.reduce((result, _x, index) => {
    index < xs.length - 1 && result.push(xs.slice(index, index + 2) as [T, T]);

    return result;
  }, [] as Array<[T, T]>);
};

/**
 * Split an array into chunks of given size
 *
 * @example
 *
 * ```ts
 * splitIntoChunks([1, 2, 3, 4, 5], 2) // [[1, 2], [3, 4], [5]]
 * ```
 */
export const splitIntoChunks = <T>(xs: T[], size: number): T[][] => {
  return xs.reduce((result, _x, index) => {
    const chunkIndex = Math.floor(index / size);

    result[chunkIndex] = result[chunkIndex] || [];
    result[chunkIndex].push(_x);

    return result;
  }, [] as T[][]);
};

export const splitIntoColumns = <T>(xs: T[], columns: number): T[][] => {
  return [...new Array(columns).keys()].map((c) =>
    xs.filter((_, i) => i % columns === c)
  );
};

export const stringToArray = (value: string): string[] => {
  return value
    .split(",")
    .map((value) => value.trim())
    .filter((value) => !!value); // Remove empty strings
};

export const findDuplicatesBy = <T, K>(
  values: T[],
  keyFn: (value: T) => K
): T[] => {
  const alreadyMet = new Map<K, boolean>();
  const duplicates: T[] = [];

  values.forEach((value) => {
    if (alreadyMet.get(keyFn(value))) {
      duplicates.push(value);
    } else {
      alreadyMet.set(keyFn(value), true);
    }
  });

  return duplicates;
};

/**
 * Return a list of unique values by reference.
 *
 * @NOTE This is a much simpler alternative to Ramda's uniq, which runs a deep check and handles
 * cyclical references as well. But if don't need anything fancy, then this is fast and with zero surprises!
 */
export const unique = <T>(values: T[]): T[] => {
  return Array.from(new Set(values));
};

export const sortByLabel = (
  a: AnyDropdownOption,
  b: AnyDropdownOption
): number => String(a.label).localeCompare(String(b.label));

export type AnyDropdownOption = { value: string; label: string };

export const mapStringEnumToDropdownOptions = (
  myEnum: Record<string, string>
): AnyDropdownOption[] =>
  Object.entries(myEnum).map(([label, value]) => ({
    value,
    label,
  }));

export const isValidJSON = (value: string): boolean => {
  try {
    JSON.parse(value);
    return true;
  } catch {
    return false;
  }
};

export const convertYoutubeLinkToEmbedYoutubeLink = (
  url: string
): string | null => {
  const matches = [...url.matchAll(new RegExp(youtubeLinkRegex, "g"))];
  const youtubeId = matches[0]?.[2];
  if (!youtubeId) {
    return null;
  }
  return `https://www.youtube.com/embed/${youtubeId}`;
};

export function binarySearch<T>(
  arr: T[],
  predicate: (value: T, index: number, array: T[]) => number
) {
  let start = 0;
  let end = arr.length - 1;
  let mid = -1;
  let lower = start;
  let upper = end;
  while (start <= end) {
    mid = Math.floor((start + end) / 2);
    const offset = predicate(arr[mid], mid, arr);
    if (offset === 0) {
      // exact match
      return { index: mid, upper: mid, lower: mid };
    } else if (offset > 0) {
      lower = mid;
      // move to end
      start = mid + 1;
    } else {
      upper = mid;
      // move to start
      end = mid - 1;
    }
  }
  return {
    index: -1,
    upper,
    lower,
  };
}

export const isOnlyEmptyValues = (obj: Record<string, unknown>): boolean => {
  return Object.values(obj).every((value) =>
    isObject(value) ? isOnlyEmptyValues(value) : isEmpty(value) || isNil(value)
  );
};

/**
 * Clean up the form values so that empty fields get replaced with null. And fields which are objects
 * with only empty values get replaced with null altogether.
 */
export const cleanupFormValues = <T extends Record<string, unknown>>(
  values: T
): T => {
  return Object.fromEntries(
    Object.entries(values).map(([key, value]) => {
      const cleanedValue = isObject(value)
        ? isOnlyEmptyValues(value)
          ? null
          : cleanupFormValues(value)
        : isEmpty(value) || isNil(value)
        ? null
        : value;

      return [key, cleanedValue];
    })
  ) as T;
};

/**
 * Avoid setting the CSS style if the value didn't change to avoid triggering useless DOM layout invalidation
 * and Repainting
 */
export const setStyleIfChanged = (
  element: HTMLElement,
  property: keyof CSSStyleDeclaration,
  value: string
) => {
  if (element.style[property] !== value) {
    // @ts-expect-error Invalid error
    element.style[property] = value;
  }
};
